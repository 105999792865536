import {Pipe, PipeTransform} from '@angular/core';
import {
  findMatchingVariantForSelection
} from '../../../../product/state/helpers/functions/find-matching-variant-for-selection';
import {ProductVariant} from '../../../../product/state/product.model';
import {
  VariantOption,
  VariantOptionGroup,
  VariantOptionValue
} from '../../../../product/state/types/available-variant-option';

@Pipe({
  name: 'isVariantOptionSelectable'
})
export class IsVariantOptionSelectablePipe implements PipeTransform {
  transform(option: VariantOptionValue, templateId: VariantOptionGroup['productAttributeTemplateId'], selectedOptions: VariantOption[], variants: ProductVariant[]) {
    const newVariantOptions = selectedOptions.map(selectedOption => selectedOption.productAttributeTemplateId === templateId
      ? {
        productAttributeTemplateId: templateId,
        value: option
      }
      : selectedOption
    );

    return findMatchingVariantForSelection({
      variantOptions: newVariantOptions,
      variants,
    }) !== undefined;
  }
}
