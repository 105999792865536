<div class="mat-headline-6">
    Alle Bezugsquellen zu {{ product?.name }}
</div>

<dqn-table
  [rows]="supplierProducts"
  [headers]="supplierProductsTableHeaders"
  [loading]="loading"
  class="supplier-product-list">
    <td
      *dqnTableCellTemplate="'image'; let supplierProduct"
      dqnTableCell>
        <div class="flex justify-center items-center">
            <img
              [src]="product.images | productDefaultImageUrl"
              [alt]="'Produktbild ' + product.name">
        </div>
    </td>

    <td
      *dqnTableCellTemplate="'name'; let supplierProduct"
      dqnTableCell>
        <span class="break-anywhere">
            {{ product.name }}
        </span>
    </td>

    <td
      *dqnTableCellTemplate="'supplier'; let supplierProduct"
      dqnTableCell>
        {{ (supplierProduct.supplier_id | supplierById | async)?.name }}
    </td>

    <td
      *dqnTableCellTemplate="'rating'; let supplierProduct"
      dqnTableCell>
        <span class="eco whitespace-nowrap">
            <ng-container *ngFor="let i of [1, 2, 3, 4]">
                <mat-icon fontSet="material-icons-round">eco</mat-icon>
            </ng-container>
            <ng-container *ngFor="let i of [1]">
                <mat-icon fontSet="material-icons-outlined">eco</mat-icon>
            </ng-container>
        </span>
    </td>

    <td
      *dqnTableCellTemplate="'price'; let supplierProduct"
      dqnTableCell>
        <app-supplier-product-price
          class="whitespace-nowrap"
          [supplierProduct]="supplierProduct" />
    </td>

    <td
      *dqnTableCellTemplate="'actions'; let supplierProduct"
      dqnTableCell>
        @if (supplierProductTableActions) {
            <ng-container
              [ngTemplateOutlet]="supplierProductTableActions"
              [ngTemplateOutletContext]="{ $implicit: supplierProduct }" />
        } @else {
            <!-- HINT: Default actions on supplier product list -->
            <div class="flex items-center gap-1">
                <button
                  mat-mini-fab
                  color="accent"
                  matTooltip="In den Einkaufswagen"
                  (click)="addToShoppingCart.emit(supplierProduct)">
                    <mat-icon>shopping_cart</mat-icon>
                </button>

                @if (selectedSupplierProduct?.id !== supplierProduct.id) {
                    <a
                      mat-icon-button
                      [routerLink]="supplierProduct.id | supplierProductDetailsRouterLink"
                      (click)="navigateToSupplierProduct.emit(supplierProduct)">
                        <mat-icon>arrow_forward</mat-icon>
                    </a>
                } @else {
                    <span class="mat-hint">Ausgewählt</span>
                }
            </div>
        }
    </td>
</dqn-table>
