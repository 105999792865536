import {inject, Pipe, PipeTransform} from '@angular/core';
import {Product} from '../../product/state/product.model';
import {SupplierProductsQuery} from '../../product/state/supplier-products/supplier-products.query';

@Pipe({
  name: 'supplierProductsForProduct$'
})
export class SupplierProductsForProductPipe implements PipeTransform {
  private readonly supplierProductsQuery = inject(SupplierProductsQuery);

  transform(productId: Product['id']) {
    return this.supplierProductsQuery.selectSupplierProductsWithSameBaseProduct(productId);
  }
}
