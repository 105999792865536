import {Pipe, PipeTransform} from '@angular/core';
import {ProductVariant} from '../../../../product/state/product.model';
import {VariantOptionGroup, VariantOptionValue} from '../../../../product/state/types/available-variant-option';

@Pipe({
  name: 'isVariantOptionSelected'
})
export class IsVariantOptionSelectedPipe implements PipeTransform {
  transform(option: VariantOptionValue, templateId: VariantOptionGroup['productAttributeTemplateId'], selectedVariant: ProductVariant) {
    if (!selectedVariant) {
      return false;
    }
    return selectedVariant.product_attributes?.some(attribute => attribute.product_attribute_template_id === templateId && attribute.value === option);
  }
}
