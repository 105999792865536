import {ComponentStore} from '@/shared/helpers/component-store/component-store';
import {inject, Injectable} from '@angular/core';
import {Query} from '@datorama/akita';
import {map, switchMap} from 'rxjs/operators';
import {
  removeProductAttributesByVariantAttributes
} from '../../../product/state/helpers/functions/remove-product-attributes-by-variant-attributes';
import {ProductVariant} from '../../../product/state/product.model';
import {VariantOption} from '../../../product/state/types/available-variant-option';
import {VariantAttributesQuery} from '../../../product/state/variant-attributes/variant-attributes.query';

interface State {
  selectedVariant: ProductVariant | null;
}

const getInitialState = (): State => ({
  selectedVariant: null,
});

@Injectable()
export class ProductVariantSelectionStore extends ComponentStore<State> {
  constructor() {
    super(getInitialState(), 'variant-selection');
  }
}

@Injectable()
export class ProductVariantSelectionQuery extends Query<State> {
  private readonly variantAttributesQuery = inject(VariantAttributesQuery);

  selectedVariant$ = this.select('selectedVariant');
  selectedOptions$ = this.selectedVariant$.pipe(
    switchMap(variant => this.variantAttributesQuery.selectVariantAttributesForProduct(variant.parent_id).pipe(
        map(variantAttributes => {
          if (!variant.product_attributes || !variantAttributes) {
            return [];
          }

          const filteredAttributes = removeProductAttributesByVariantAttributes(variant.product_attributes, variantAttributes);

          return this.mapProductAttributesToAttributeOptions(filteredAttributes);
        }),
      ),
    ),
  );

  get selectedVariant() {
    return this.getValue().selectedVariant;
  }

  get selectedOptions() {
    if (!this.selectedVariant?.product_attributes) {
      return [];
    }

    const filteredAttributes = removeProductAttributesByVariantAttributes(
      this.selectedVariant?.product_attributes,
      this.variantAttributesQuery.getVariantAttributesForProduct(this.selectedVariant?.parent_id) ?? [],
    );

    return this.mapProductAttributesToAttributeOptions(filteredAttributes ?? []);
  }

  constructor(protected override store: ProductVariantSelectionStore) {
    super(store);
  }

  private mapProductAttributesToAttributeOptions(attributes: ProductVariant['product_attributes']): VariantOption[] {
    return attributes.map(attribute => ({
      productAttributeTemplateId: attribute.product_attribute_template_id,
      value: attribute.value,
    }));
  }
}

@Injectable()
export class ProductVariantSelectionService {
  constructor(private store: ProductVariantSelectionStore) {
  }

  setSelectedVariant(variant: ProductVariant) {
    this.store.update({selectedVariant: variant});
  }
}

export const provideProductVariantSelectionState = () => ([ProductVariantSelectionStore, ProductVariantSelectionQuery, ProductVariantSelectionService]);
