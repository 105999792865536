import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {SupplierProduct} from '../../../../../product/state/supplier-products/supplier-product.model';
import {calculateScalePrice} from './helpers/calculate-scale-price';

@Component({
  selector: 'app-supplier-product-price',
  templateUrl: './supplier-product-price.component.html',
  styleUrls: ['./supplier-product-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplierProductPriceComponent {
  @Input() supplierProduct!: SupplierProduct;
  @Input() useStyling!: boolean;
  @Input() showScalePrice = false;

  get product() {
    return this.supplierProduct.product;
  }

  get unitPrice() {
    return this.supplierProduct.unit_price ? this.supplierProduct.unit_price / 100 : null;
  }

  get frequencyPrice() {
    return this.supplierProduct.billing_frequency_price ? this.supplierProduct.billing_frequency_price / 100 : null;
  }

  get canDisplayScalePrices() {
    return this.showScalePrice && this.product.basic_quantity && this.product.selling_quantity;
  }

  get unitScalePrice() {
    return calculateScalePrice(this.unitPrice, this.product.basic_quantity, this.product.selling_quantity);
  }

  get frequencyScalePrice() {
    return calculateScalePrice(this.frequencyPrice, this.product.basic_quantity, this.product.selling_quantity);
  }
}
