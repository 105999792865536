import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {
  collectAvailableVariantOptions
} from '../../../product/state/helpers/functions/collect-available-variant-options';
import {findVariantForNewSelection} from '../../../product/state/helpers/functions/find-variant-for-new-selection';
import {ProductVariant} from '../../../product/state/product.model';
import {
  VariantOption,
  VariantOptionGroup,
  VariantOptionValue
} from '../../../product/state/types/available-variant-option';
import {VariantAttribute} from '../../../product/state/variant-attributes/variant-attribute.model';
import {
  ProductVariantSelectionQuery,
  ProductVariantSelectionService,
  provideProductVariantSelectionState
} from './product-variant-selection.state';

@Component({
  selector: 'app-product-variant-selection',
  templateUrl: './product-variant-selection.component.html',
  styleUrls: ['./product-variant-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    provideProductVariantSelectionState(),
  ]
})
export class ProductVariantSelectionComponent implements OnInit {
  readonly variantSelectionQuery = inject(ProductVariantSelectionQuery);
  readonly variantSelectionService = inject(ProductVariantSelectionService);

  @Input({required: true}) variants: ProductVariant[];
  @Input({required: true}) variantAttributes: VariantAttribute[];
  @Input() initialSelection: ProductVariant | null = null;
  @Input() loading = false;

  @Output() selectVariant = new EventEmitter<ProductVariant>();

  // TODO: Move available options and add metadata (selected, "disabled") instead of separate selectedOptions?
  get availableOptions(): VariantOptionGroup[] {
    if (!this.variants || !this.variantAttributes) {
      return [];
    }

    return collectAvailableVariantOptions(this.variants, this.variantAttributes);
  }

  ngOnInit() {
    if (this.initialSelection) {
      this.variantSelectionService.setSelectedVariant(this.initialSelection);
    }
  }

  selectOption(option: VariantOptionValue, templateId: VariantOption['productAttributeTemplateId']) {
    // Skip selection if option is already selected
    if (this.variantSelectionQuery.selectedOptions.some(selectedOption => selectedOption.productAttributeTemplateId === templateId && selectedOption.value === option)) {
      return;
    }

    const variantToSelect = findVariantForNewSelection({
      newVariantOption: {
        productAttributeTemplateId: templateId,
        value: option,
      },
      previousVariantOptions: this.variantSelectionQuery.selectedOptions,
      variants: this.variants,
    }) || null;

    this.variantSelectionService.setSelectedVariant(variantToSelect);
    this.selectVariant.emit(variantToSelect);
  }
}
