import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef
} from '@angular/core';
import {DqnTableHeader} from '@dqn/components/table';
import {environment} from '../../../../../environments/environment';
import {Product} from '../../../product/state/product.model';
import {SupplierProduct} from '../../../product/state/supplier-products/supplier-product.model';
import {
  SupplierProductsProductListActionsDirective
} from './directives/supplier-products-product-list-actions.directive';

@Component({
  selector: 'app-supplier-products-product-list',
  templateUrl: './supplier-products-product-list.component.html',
  styleUrls: ['./supplier-products-product-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplierProductsProductListComponent implements OnInit {
  @Input({required: true}) supplierProducts!: SupplierProduct[];
  @Input({required: true}) product!: Product;
  @Input() loading = false;
  @Input() selectedSupplierProduct: SupplierProduct | null = null;

  @Output() addToShoppingCart = new EventEmitter<SupplierProduct>();
  @Output() navigateToSupplierProduct = new EventEmitter<SupplierProduct>();

  @ContentChild(SupplierProductsProductListActionsDirective, {read: TemplateRef}) supplierProductTableActions: TemplateRef<{
    $implicit: SupplierProduct
  }>;

  supplierProductsTableHeaders: DqnTableHeader[] = [
    {key: 'image', title: ''},
    {key: 'name', title: 'Produkt'},
    {key: 'supplier', title: 'Bezugsquelle'},
    {key: 'rating', title: 'Bewertung'},
    {key: 'price', title: 'Preis'},
    // TODO: Migrate to table actions as soon as the selection is presented in another way ("Selected" should be the last cell)
    {key: 'actions', title: ''},
  ];

  ngOnInit() {
    // Hide 'addToBasket' if shop is not active
    if (!environment.shopActive) {
      this.supplierProductsTableHeaders = this.supplierProductsTableHeaders.filter(
        column => column.key !== 'addToBasket'
      );
    }
  }
}
